<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Dato Financiero - Editar</strong>
            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/dato-financiero/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row class="row justify-content-center">
                <b-col md="2">
                  <b-form-group label="Tipo:">
                    <b-form-select disabled v-model="financial_data.id_product" :options="products"></b-form-select>
                    <small v-if="errors.id_product"  class="form-text text-danger" >Seleccione un Tipo</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="TEA:">
                    <b-form-input type="number" disabled step="any" v-model="financial_data.tea"></b-form-input>
                    <small v-if="errors.tea"  class="form-text text-danger" >Ingrese un valor</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="TMA:">
                    <b-form-input type="number" disabled step="any" v-model="financial_data.tma"></b-form-input>
                    <small v-if="errors.tea"  class="form-text text-danger" >Ingrese un valor</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Por defecto?:">
                    <b-form-select v-model="financial_data.default" :options="defaults"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado:">
                    <b-form-select v-model="financial_data.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row class="row justify-content-center">
  
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components

import LoadingComponent from './../pages/Loading'
export default {
  name: "FinancialDataEdit",
  props: ["id_financial_data"],
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'FinancialData',
      role: 3,
      financial_data: {
        id_financial_data:'',
        id_product:'',
        tea:'',
        tem:'',
        tma:'',
        default:'1',
        state:'1',
      },
      products: [],
      defaults: [
        {value:1, text:'SI'},
        {value:0, text:'NO'},
      ],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        id_product:false,
        tea: false,
      },
    };
  },
  mounted() {
    this.ListProducts();
    this.ViewFinancialData();
  },
  methods: {
    EditFinancialData,
    Validate,
    ViewFinancialData,
    ListProducts,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ListProducts() {

  let me = this;
  let url = me.url_base + "product/actives";
  me.products = [{ value: '', text:'- Seleccione una opcion - '}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.products.push({value: element.id_product, text: element.name });
      });
    }

  });
}

function ViewFinancialData() {
  let me = this;
  let id_financial_data = je.decrypt(this.id_financial_data);
  let url = me.url_base + "financial-data/view/"+id_financial_data;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.financial_data.id_financial_data = response.data.result.id_financial_data;
        me.financial_data.id_product = response.data.result.id_product;
        me.financial_data.tea = response.data.result.tea;
        me.financial_data.tma = response.data.result.tma;
        me.financial_data.default = response.data.result.default;
        me.financial_data.state = response.data.result.state;
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function EditFinancialData() {
  let me = this;
  let url = me.url_base + "financial-data/edit";
  let data = me.financial_data;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_product = this.financial_data.id_product.length == 0 ? true : false;
  this.errors.tea = this.financial_data.tea.length == 0 ? true : false;

  if (this.errors.id_product) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.tea) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el dato financiero ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditFinancialData();
    }
  });
}
</script>
